import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  return (
    <Container className="container-space article-text">
      <Row>
        <Col>
          <p className="h4 pb-4">
            {t('WhatDoesItCostToBuildQualitySystemArticleSubTitle')}
          </p>
          <h2 className="text-blue">
            {t('WhatDoesItCostToBuildQualitySystemArticleTitle1')}
          </h2>
          <p className="pb-4">
            {t('WhatDoesItCostToBuildQualitySystemArticlePara1')}
          </p>

          <h2 className="text-blue">
            {t('WhatDoesItCostToBuildQualitySystemArticleTitle2')}
          </h2>
          <p className="pb-4">
            {t('WhatDoesItCostToBuildQualitySystemArticlePara2')}
          </p>
          <h2 className="text-blue">
            {t('WhatDoesItCostToBuildQualitySystemArticleTitle3')}
          </h2>
          <p className="pb-4">
            {t('WhatDoesItCostToBuildQualitySystemArticlePara3')}
          </p>
          <h2 className="text-blue">
            {t('WhatDoesItCostToBuildQualitySystemArticleTitle4')}
          </h2>
          <p className="pb-4">
            {t('WhatDoesItCostToBuildQualitySystemArticlePara4')}
          </p>
          <h2 className="text-blue">
            {t('WhatDoesItCostToBuildQualitySystemArticleTitle5')}
          </h2>
          <p className="pb-4">
            {t('WhatDoesItCostToBuildQualitySystemArticlePara5')}
          </p>
          <h2 className="text-blue">
            {t('WhatDoesItCostToBuildQualitySystemArticleTitle6')}
          </h2>
          <p>{t('WhatDoesItCostToBuildQualitySystemArticlePara6')}</p>
          <p>{t('WhatDoesItCostToBuildQualitySystemArticlePara7')}</p>
          <p>{t('WhatDoesItCostToBuildQualitySystemArticlePara8')}</p>
        </Col>
      </Row>
    </Container>
  );
};
