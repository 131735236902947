import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LinkTo from '../../components/LinkTo';
import BackgroundImage from '../../components/images/BackgroundImage';
import urls from '../../components/urls';

export default () => {
  const { t } = useTranslation();
  return (
    <BackgroundImage className="hero">
      <Container>
        <Row>
          <Col>
            <h1 className="text-center">
              {t('WhatDoesItCostToBuildQualitySystemArticleHeroTitle1')}
            </h1>
            <p className="text-center mt-5">
              {t('WhatDoesItCostToBuildQualitySystemArticleHeroPara1')}
            </p>
            <p className="text-center mt-5">
              {t('WhatDoesItCostToBuildQualitySystemArticleHeroPara2')}
              <LinkTo
                url={urls.home}
                text={t('WhatDoesItCostToBuildQualitySystemArticleHeroPara3')}
                hoverWhite
              />
            </p>
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
