import { Link } from 'gatsby';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ContactRequest from '../components/ContactRequest';
import QualityManualRequestWithBg from '../components/QualityManualRequestWithBg';
import Layout from '../components/layout';
import SEO from '../components/seo';
import urls from '../components/urls';
import withI18next from '../components/withI18Next';
import Asset25 from '../images/Asset-25.svg';
import QmcloudsOgImg from '../images/qmclouds-og-img.png';
import WhatDoesItCostToBuildQualitySystemArticle from '../pages-partials/article-what-does-it-cost-to-build-quality-system/WhatDoesItCostToBuildQualitySystemArticle';
import WhatDoesItCostToBuildQualitySystemHero from '../pages-partials/article-what-does-it-cost-to-build-quality-system/WhatDoesItCostToBuildQualitySystemHero';

const WhatDoesItCostToBuildQualitySystemPage = ({
  pageContext: { locale },
  t,
}: any) => (
  <Layout locale={locale}>
    <SEO
      title={t('WhatDoesItCostToBuildQualitySystemSeoTitle')}
      description={t('WhatDoesItCostToBuildQualitySystemSeoDescription')}
      pageImage={QmcloudsOgImg}
      pageUrl="https://qmclouds.com/what-does-it-cost-to-build-quality-system"
      lang={locale}
    />
    <WhatDoesItCostToBuildQualitySystemHero />
    <WhatDoesItCostToBuildQualitySystemArticle />
    <QualityManualRequestWithBg />
    <Container className="container-space article-text">
      <Row className="mt-5">
        <Col lg={6} className="mt-5">
          <ContactRequest />
        </Col>
        <Col lg={6}>
          <img
            src={Asset25}
            alt={t('ArticlePiece1Alt')}
            className="img img-w-350 block m-auto"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to={urls.articlePageUrl}>{t('BackToArticles')}</Link>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default withI18next({ ns: 'common' })(
  WhatDoesItCostToBuildQualitySystemPage
);
